import React from "react";
import styled from "styled-components";
import toast from "react-hot-toast";
import { Controller, useForm } from "react-hook-form";

import { Input } from "../Form";
import { PrimaryButton } from "../Buttons";
import { useAuth } from "../../hooks/useAuth";

import LanguageProps from "../../interfaces/LanguageProps";
import { navigate } from "gatsby";

const Form = styled.form`
  display: grid;
  gap: 2rem 0;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.lightGrey};

  button {
    margin-left: auto;
  }

  @media screen and (min-width: ${({ theme }) => theme.breakpoints.laptop}) {
    width: 50%;
  }
`;

interface RegisterFormProps extends LanguageProps {
  className?: string;
}

interface RegisterFormData {
  companyName: string;
  companyRegistrationNumber: string;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  jobTitle: string;
  emailAddress: string;
  password: string;
}

const RegisterForm: React.FC<RegisterFormProps> = ({ language, className }) => {
  const isEnglish = language === "en";
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const { register } = useAuth();
  const { handleSubmit, control } = useForm<RegisterFormData>({
    mode: "onSubmit",
  });

  const onValidSubmit = async (registerData: RegisterFormData) => {
    setIsLoading(true);
    const {
      companyName,
      emailAddress,
      password,
      firstName,
      lastName,
      phoneNumber,
      companyRegistrationNumber,
      jobTitle,
    } = registerData;

    toast
      .promise(
        register(
          emailAddress,
          password,
          companyName,
          firstName,
          lastName,
          phoneNumber,
          companyRegistrationNumber,
          jobTitle
        ),
        {
          loading: "Registreren..",
          success: "Geregistreerd",
          error: "Geen toegang",
        }
      )
      .then(() => {
        isEnglish ? navigate("/app/en/account/") : navigate("/app/account/");
        setIsLoading(false);
      })
      .catch((error) => console.error(error));
  };

  const onInvalidSubmit = async () => {
    toast.error(
      isEnglish
        ? "Please fill in all details"
        : "Vul alstublieft alle gegevens in"
    );
  };

  return (
    <Form
      className={className}
      onSubmit={handleSubmit(onValidSubmit, onInvalidSubmit)}
    >
      <Controller
        control={control}
        name="emailAddress"
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            id="emailAddress"
            autoComplete="username"
            name="emailAddress"
            type="email"
            label={isEnglish ? "E-mail Address" : "E-mailadres"}
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="password"
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            id="password"
            autoComplete="current-password"
            name="password"
            type="password"
            label={isEnglish ? "Password" : "Wachtwoord"}
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="companyName"
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            id="companyName"
            name="companyName"
            type="text"
            label={isEnglish ? "Company Name" : "Bedrijfsnaam"}
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="companyRegistrationNumber"
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            id="companyRegistrationNumber"
            name="companyRegistrationNumber"
            type="text"
            label={isEnglish ? "KVK" : "KVK"}
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="firstName"
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            id="firstName"
            name="firstName"
            type="text"
            label={isEnglish ? "First Name" : "Voornaam"}
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="lastName"
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            id="lastName"
            name="lastName"
            type="text"
            label={isEnglish ? "Last Name" : "Achternaam"}
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="jobTitle"
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            id="jobTitle"
            name="jobTitle"
            type="text"
            label={isEnglish ? "Job Title" : "Functietitel"}
            onChange={field.onChange}
          />
        )}
      />
      <Controller
        control={control}
        name="phoneNumber"
        rules={{ required: true }}
        render={({ field }) => (
          <Input
            id="phone"
            name="phone"
            type="tel"
            label={isEnglish ? "Phone Number" : "Telefoonnummer"}
            onChange={field.onChange}
          />
        )}
      />
      <PrimaryButton type="submit" disabled={isLoading}>
        {isEnglish ? "Register" : "Registreren"}
      </PrimaryButton>
    </Form>
  );
};

export default RegisterForm;
